import React, { useState, useEffect } from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import DashboardItems from "./DashboardItems";
import { useAuth } from "./../util/auth";
import { apiRequest } from "../util/util";
import Button from "./Button";

function DashboardSection(props) {
  const auth = useAuth();

  const [apiProfile, setApiProfile] = useState(null);

  function refreshProfile() {
    apiRequest("profile", "GET").then((result) => {
      setApiProfile(result);
    });
  }

  function googleLogout() {
    apiRequest("googlelogout", "GET").then((result) => {
      refreshProfile();
    });
  }

  useEffect(() => { refreshProfile() }, []);

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="flex flex-wrap">
          <div className="p-4 w-full md:w-1/2">
            <div className="rounded border border-shadow">
              <DashboardItems />
            </div>
          </div>
          
            {apiProfile && !apiProfile.hasGoogleCreds &&
            <div className="p-4 w-full md:w-1/2">
              <div className="p-6 rounded border border-shadow text-peach prose prose-a:text-peach max-w-none">
  
                <h3 className="mb-4 text-palepeach">Link your Google account</h3>
                <Button
                      size="lg"
                      variant="simple"
                      isBlock={true}
                      onClick={() => {
                        window.location.href = '/googlelogin';
                      }}
                      startIcon={
                        
                          <img
                            src={`https://uploads.divjoy.com/icon-google.svg`}
                            alt={"Google"}
                            className="w-5 h-5"
                          />
                        
                      }
                    >
                    </Button>
              </div>
            </div>
            }
            {apiProfile && apiProfile.hasGoogleCreds &&
            <div className="p-4 w-full md:w-1/2">
            <div className="p-6 rounded border border-shadow text-peach prose prose-a:text-peach max-w-none">

              <h3 className="mb-4 text-palepeach">Unlink your Google account</h3>
              <Button
                    size="lg"
                    variant="simple"
                    isBlock={true}
                    onClick={() => googleLogout()}
                  >
                    Unlink
                  </Button>
            </div>
          </div>
            }
          <div className="p-4 w-full md:w-1/2">
            <div className="p-6 rounded border border-shadow text-peach prose prose-a:text-peach max-w-none">

              <h3 className="mb-4 text-palepeach">Extra debug info</h3>
              <div>
                You are signed in as <strong className="text-vermillion">{auth.user.email}</strong>.
              </div>
              <div>
                {apiProfile && 
                  <div className="w-full">
                    Server authenticated.<br/>
                    hasGoogleCreds: {apiProfile.hasGoogleCreds ? "✅" : "🚫"}<br/>
                    sub: {apiProfile.user.sub}<br/>
                  </div>
                }
              </div>

            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default DashboardSection;
