import React from "react";
import Meta from "./../components/Meta";
import CtaSection from "./../components/CtaSection";

import { useAuth } from "./../util/auth";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

function IndexPage(props) {
  const auth = useAuth();

  return (
    <>
      {(auth.user) ? <Redirect to="/dashboard" /> : <></>}
      <Meta />
      {/* <HeroSection2
        title="Build web applications and websites with ease"
        subtitle="Carefully crafted, easy to customize, fully responsive UI components for your Tailwind CSS based projects."
        strapline=""
        size="lg"
        bgColor="bg-blue-900"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-white"
      /> */}
      {/* <FeaturesSection
        title="Amazing features packed in a neat package"
        subtitle="You will love working with your newly updated and customized dashboard."
        strapline="It's time to build"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      /> */}
      {/* <ClientsSection
        title="These companies trust us"
        subtitle=""
        strapline=""
        size="md"
        bgColor="bg-blue-800"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-white"
      />
      <TestimonialsSection
        title="Customer Testimonials"
        subtitle=""
        strapline="Real Feedback"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      /> */}
      <CtaSection
        title={
          <>
            Create an account.
          </>
        }
        subtitle="yolo"
        strapline=""
        size="md"
        bgColor="bg-vbg"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-peach"
      />
    </>
  );
}

export default IndexPage;
