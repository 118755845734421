import React from "react";
import Meta from "./../components/Meta";
import DashboardSection from "./../components/DashboardSection";
import { requireAuth } from "./../util/auth";
const greetingTime = require("greeting-time");

function DashboardPage(props) {
  return (
    <>
      <Meta title="Vermillion" />
      <DashboardSection
        title={"👋 " + greetingTime(new Date())}
        subtitle=""
        strapline=""
        size="md"
        bgColor="bg-vbg"
        textColor="text-palepeach"
      />
    </>
  );
}

export default requireAuth(DashboardPage);
